<template>
	<div class="page page_zctype">
		<div style="width: 45%; height: 100%; display: inline-block;vertical-align: top; ">
			<el-card>
				<div slot="header" class="clearfix">

					<span>设备分类列表</span>
					<el-button style="float: right; padding: 3px 0" type="text" @click="addArea()">添加大分类</el-button>

				</div>
				<div class="linetree" style="">
					<el-tree class="tree" :highlight-current="true" :data="treeData" :props="defaultProps" :default-expand-all="true"
					 :expand-on-click-node="false">

						<div class="custom-tree-node flexBetween" slot-scope="{ node, data }">
							<span class="flex_1" @click="editArea(node)">{{ node.label }}</span>

							<div class="cus-tree-node_btn">
								<i v-if="node.data.parent_code<=0" class="el-icon-circle-plus-outline" style="color: #0086B3; margin-right: 10px;"
								 @click="addArea(node)" title="添加附属分类"></i>
								<i class="el-icon-edit" style="color: #00E0E0; margin-right: 10px;" title="编辑" @click="editArea(node)"></i>
								<i class="el-icon-delete" style="color: orangered; margin-right: 0px;" title="删除" @click="deleteArea(node)"></i>
							</div>

						</div>

					</el-tree>
				</div>
			</el-card>

		</div>

		<div v-show="dialogVisible" style=" width: 35%;position: fixed; top:100px; right: 100px;">

			<el-card>
				<div slot="header" class="clearfix">
					<span>分类名称:{{EditItem.value}}</span>

				</div>
				<div>
					<el-form ref="form" :model="EditItem" label-width="80px">

						<el-form-item label="分类名称:">
							<el-input v-model="EditItem.cname" placeholder="分类中文名称"></el-input>

						</el-form-item>
						<el-form-item label="英文编码:">
							<el-input v-model="EditItem.ename" placeholder="拼音首字母缩写,不要太长"></el-input>
						</el-form-item>
						<el-form-item label="分类代码:">
							<el-input v-model="EditItem.code" placeholder="国标分类代码"></el-input>
						</el-form-item>

						<el-form-item label="计量单位:">
							<el-input v-model="EditItem.unit" placeholder="请输入计量单位"></el-input>
						</el-form-item>
						<el-divider style="width: 100%"></el-divider>
						<div style="color: #409EFF;margin: 10px 0 10px 0;">分类属性</div>

						<el-row v-for="(c,idx) in cus_list" :key="idx" class="flexStart drawer_box">
							<el-col :span="10">
								<el-form-item label="字段名称:">
									<el-input size="small" v-model="c.name" placeholder="字段名称"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="8" style="margin-left: 10px;">
								<el-form-item label="字段类型:">
									<el-select size="small" v-model="c.type" placeholder="请选择">
										<el-option value="string" label="文本"></el-option>
										<el-option value="number" label="数值"></el-option>
										<el-option value="date" label="日期"></el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<el-tag @click="delCus(c,idx)" type="danger" size="mini" style="margin-left: 20px;"><i class="el-icon-remove"></i></el-tag>
						</el-row>
						<el-row style="margin: 20px 0">
							<el-button @click="addCus" title="新增系定义属性" size="mini" type="primary" plain icon="el-icon-plus" circle></el-button>
						</el-row>
					</el-form>
					<el-divider style="width: 100%;"></el-divider>
					<el-row style="margin-top: 20px">
						<el-col :span="24" style="text-align: right;">
							<el-button type="primary" plain @click="dialogVisible=false">取消</el-button>
							<el-button type="primary" @click="saveArea">保存</el-button>
						</el-col>
					</el-row>
				</div>
			</el-card>
		</div>
	</div>
</template>

<script>
	export default {
		components: {},
		data () {
			return {
				treeData: [],
				defaultProps: {
					children: 'children',
					label: 'cname'
				},
				dialogVisible: false,
				EditItem: {
					id: 0,
					ename: "",
					cname: "",
					value: "0",
					remark: "",
					code:"",
					unit:"",
					type:"zc",
					parent_code:0
				},
				cus_list:[],
			}
		},

		mounted () {
			this.getArea();
		},
		methods: {
			getArea () {

				this.$http.post("/api/zc_type",{type:"zc"}).then(res => {
					this.treeData = res.data;
				})
			},

			addArea (node) {
				console.log(node)

				console.log('addArea')
				if (node) {
					this.EditItem = {
						id: 0,
						ename: "",
						cname: "",
						value: node.data.cname,
						remark: "",
						unit:"",
						type:"zc",
						code:"",
						parent_code:node.data.code
					}
				} else {
					this.EditItem = {
						id: 0,
						ename: "",
						cname: "",
						value: "0",
						remark: "",
						unit:"",
						type:"zc",
						code:"",
						parent_code:0

					}
				}
				this.cus_list = []
				this.dialogVisible = true
				console.log(this.EditItem)
			},
			editArea (node) {
				console.log(node)

				this.EditItem = {
					id: node.data.id,
					ename: node.data.ename,
					cname: node.data.cname,
					value: node.data.value,
					remark: node.data.remark,
					unit:node.data.unit,
					type:node.data.type,
					code:node.data.code,
					parent_code:node.data.parent_code

				}
				if(node.data.zdjson&&node.data.zdjson!=''){
					this.cus_list = JSON.parse(node.data.zdjson)
				}else{
					this.cus_list = []

                }
				this.dialogVisible = true
            },

			deleteArea (node) {
				this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post("/api/zc_type_delete", {
						id: node.data.id
					}).then(res => {
						this.$message({
							type: 'success',
							message: '删除成功'
						});
						this.getArea();
					})

				}).catch(() => {

				});
			},
			saveArea () {
				let _this = this
				let data = JSON.parse(JSON.stringify(this.EditItem))
				data.remark = ""
				data.zdjson = JSON.stringify(_this.cus_list)
				data.zccode =data.code
				this.$http.post("/api/zc_type_save", data).then(res => {
					_this.dialogVisible = false
					_this.$message({
						type: 'success',
						message: '保存成功'
					});
					_this.getArea()
				})


			},
			addCus(){
				this.cus_list.push({name:"",type:"string"})
            },
			delCus(itam,index){
				this.cus_list.splice(index,1)
            },
		}
	}
</script>

<style lang="less" type="text/scss">
	.page_zctype {
        .el-divider{
            margin-top: 0;
            margin-bottom: 0px;
        }
        .drawer_box{
            .el-form-item {
                margin-bottom: 0px;
            }
        }
    }
</style>
<style lang="less" type="text/scss" scoped="scoped">
	.custom-tree-node {
        width: 100%;
    }
    ::v-deep .linetree {
        .el-tree > .el-tree-node > .el-tree-node__content:first-child {
            
            &::before,
            &::after {
                border: none;
            }
        }
        
        .el-tree-node .el-tree-node__content {
            height: 30px;
            
            &::before,
            &::after {
                content: "";
                position: absolute;
                right: auto;
            }
            
            &::before {
                border-left: 1px solid #c0c4cc;
                bottom: 50px;
                height: 100%;
                top: 0;
                width: 1px;
                margin-left: -5px;
                margin-top: -15px;
                z-index: 9;
            }
            
            &::after {
                border-top: 1px solid #c0c4cc;
                height: 20px;
                top: 14px;
                width: 10px;
                margin-left: -5px;
                z-index: 9;
            }
        }
        
        .el-tree .el-tree-node {
            position: relative;
        }
    }
</style>
